<template>
  <div class="companyProfile">
<!--    简介概述-->
    <div class="Introduction-box" id="box-1">
              <h1>简介概述</h1>
              <div class="Introduction-info">
                  <div v-if="IntroductionIndex==0">
                    <h1>成立于2000年</h1>
                    <div>
                      深圳市安软科技股份有限公司成立于2000年，公司始终专注于中国公共安全领域信息化、 智能化系统的产品研发、技术服 务和系统建设。随着人工智能、大数据技术的应用和发展，近年来逐步发展成为一家面向各行业提供智慧视觉大数据应用 整体解决方案的厂家。
                    </div>
                  </div>
                <div v-if="IntroductionIndex==1">
                  <h1>低成本 高智能 广覆盖</h1>
                  <div>
                    公司坚持以不断满足客户需求为中心，以提升公安和各行业业务实战应用效能为目标，依托于天津、深圳、北京四大研发 中心，坚持进行技术和方案革新。先后推出的“公安综合信息应用系统”、“移动警务系统”、“新一代城市级视图大数据智能 防控系统”和“安消一体化综合智慧防控系统”四大解决方案，业务遍及全国20多个省200多个地市。                  </div>
                </div>
                <div v-if="IntroductionIndex==2">
                  <h1>荣誉资质</h1>
                  <div>
                    公司的产品和解决方案先后获得国家科学技术进步二等奖、公安部科技成果一等奖等多项荣誉，参与了杭州G20峰会、上 合青岛峰会和多项国际国内大型会议的信息化建设和安保工作，受到相关部门的表彰。
                  </div>
                  <div style="display: flex;justify-content: space-around;line-height: 1;margin-top: 50px">
                    <div>
                      <h1>100+</h1>
                      <p>专项技术</p>
                    </div>
                    <div>
                      <h1>43+</h1>
                      <p>发明专利</p>
                    </div>
                    <div>
                      <h1>200+</h1>
                      <p>软件著作权</p>
                    </div>
                  </div>
                </div>
                <div v-if="IntroductionIndex==3">
                  <h1>我们的愿景、使命及价值观</h1>
                  <div>
                      <p>企业使命——铸行业利器 促社会和谐</p>
                      <p>经营理念——坚持、敬业、诚信、团结</p>
                      <p>研发理念——客户第一、需求 导向、系统思维、 质量优先</p>
                      <p>核心价值观——以满足客户需求为中心，以提供一流产品及服务为己任</p>
                      <p>公司愿景——中国公共安全行业信息化、智能化龙头企业</p>
                  </div>
                </div>
              </div>
              <div class="Introduction-tools">
                  <div v-for="(item,index) in toolsList"
                      :key="index"
                       :class="IntroductionIndex==index?'active':''"
                       @click="IntroductionIndex=index"
                  >
                    {{item.name}}
                  </div>
              </div>
    </div>
<!--    战略主轴-->
    <div class="strategic-box" id="box-2">
              <h1>战略主轴</h1>
              <div class="strategic-type">
                  <div>多维感知</div>
                  <div class="round"></div>
                  <div>主动预警</div>
                  <div class="round"></div>
                  <div>智慧赋能</div>
                  <div class="round"></div>
                  <div>服务百业</div>
              </div>
              <div class="strategic-info">
                  <div class="item-info">
                      <h2>智慧公安赋能</h2>
                      <div>
                        针对当前日益复杂的社会管控需求和各行业大规模视频应用痛点， 安软慧视依托“全息感知、数据智能、行业应用赋能”，开创了公安 视图智能化应用的新时代，已成为视图智能化应用新时代的践行者 和领导者。
                      </div>
                  </div>
                  <div class="item-info">
                  <h2>千行百业赋能</h2>
                  <div>
                    不断向智慧园区、智慧社区、智慧校园、智慧交通、智慧森林、智 慧边防、智慧景区、智慧海防等场景进行AI赋能，大幅降低人工智 能落地成本，帮助千行百业实现高效率、低成本、规模化的 AI 创
                    新和落地。
                  </div>
                </div>
              </div>
    </div>
<!--    研究与应用-->
    <div class="application-box" id="box-3">
      <h1>研究与应用</h1>
      <div class="text-info">深耕智慧城市实战场景 “智造”全景应用极致体验</div>
      <div class="application-content">
        <el-carousel  :autoplay="true"  indicator-position="none" height="480px">
          <el-carousel-item>
            <div class="huiShi-bg">
                  <div class="content">
                      <div>
                        <img width="100%" src="../../assets/huishi-img.jpg" alt="">
                      </div>
                      <div>
                        <h2>安软慧视</h2>
                        <div>
                          依托公安行业技术应用能力进行行业泛化，面向智慧城市 各行各业提供智慧视觉大数据应用整体解决方案的厂家。
                        </div>
                      </div>
                  </div>
            </div>
          </el-carousel-item>
<!--          <el-carousel-item>-->
<!--            <div class="azk-bg">-->
<!--              <div class="content">-->
<!--                <div>-->
<!--                  <img width="100%" src="../../assets/azk-img.jpg" alt="">-->
<!--                </div>-->
<!--                <div>-->
<!--                  <h2>安智控科技</h2>-->
<!--                  <div>-->
<!--                    专注于将先进的智慧视觉和大数据分析技术融合，向园 区、校园、医院、社区、环保、隧道、工地、城管等场景 进行智慧赋能，提供完善的综合智慧防控系列解决方案。                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-carousel-item>-->
          <el-carousel-item>
            <div class="by-bg">
              <div class="content">
                <div>
                  <img width="100%" src="../../assets/by-img.jpg" alt="">
                </div>
                <div>
                  <h2>博跃科技</h2>
                  <div>
                    专注于公安行业大规模的视频AI智能化应用解决方案，在 智能化视频大数据应用方向具全栈开发能力，其中专业研 发的分布式架构大数据存取技术，有效实现了大规模的应 用群集的部署和稳定运行。                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="yjy-bg">
              <div class="content">
                <div>
                  <img width="100%" src="../../assets/yjy-img.jpg" alt="">
                </div>
                <div>
                  <h2>天津研究院</h2>
                  <div>
                    致力于人工智能算法研究，同时专注于计算机视觉和边缘 计算技术，针对生产环境的边缘节点，提供多种业务场景 下高精度的智能算法方案。                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
<!--    发展历程-->
    <div class="experience-box" id="box-4">
     <div class="experience-content">
            <div class="experience-info">
              <h1 style="font-size: 32px;padding-left: 70%">发展历程</h1>
              <el-carousel  :autoplay="false"
                            ref="year"
                            arrow="never"
                            :initial-index="yearIndex"
                            indicator-position="none" height="300px">
                <el-carousel-item
                    v-for="(item,index) in yearList"
                    :key="index"
                >
                  <h1 style="padding-left: 70%">{{yearList[yearIndex].name}}</h1>
                  <div style="padding-left: 70%"
                      v-for="(item1,index1) in item.text"
                      :key="index1"
                  >
                      {{item1}}
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="experience-tools">
                  <div class="tools-item"
                        v-for="(item,index) in yearList"
                       :key="index"
                       :class="yearIndex==index?'active':''"
                       @click="changeYear(index)"
                  >
                    {{item.name}}
                  </div>
            </div>
     </div>
    </div>
    <div class="companyProfile-footer">
      <div>
          <img src="../../assets/xinwen-icon.png" alt="">
          <h1>新闻资讯</h1>
          <h5>欢迎了解安软科技，查看更多新闻资讯</h5>
          <router-link to="/news">点击查看</router-link>
      </div>
      <div>
          <img src="../../assets/jiaru-icon.png" alt="">
          <h1>加入安软</h1>
          <h5>欢迎加入安软科技，投递简历</h5>
          <router-link to="/recruitment">岗位查看</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "companyProfile",
  data(){
    return {
      IntroductionIndex:0,
      toolsList:[
        {name:'20年+'},
        {name:'解决方案'},
        {name:'荣誉资质'},
        {name:'我们目标'},
      ],
      yearList:[
        {name:2016,text:[
            '参与承建杭州G20峰会的安保项目，并获得杭州市公安局表彰。 2010年成为领先的移动警务通终端设备供应商，公司开发的移动警务系 统通过了公安部专家组验收。',
            '2006年全面转向移动警务系统研发，荣获公安部科学技术奖一等奖。 2003年公司开发的“公安综合信息查询系统”，荣获公安部科学技术奖三 等奖。'
          ]},
        {name:2017,text:["推出智慧物联系统，成功承建江西景德镇“智慧城市物联网电动自动车综 合管控“项目。"]},
        {name:2018,text:[
            "推出集安防、消防、环保、防疫于一体化的综合智慧防控系统， 向城管、园区、校园、医院、工地、景区等行业场景进行智慧赋能。"
          ]},
        {name:2020,text:[
            "多地落地实施万路级“新一代城市级智慧视图大数据网格防控系统”， 实现城市传统视频智能化与智能视频建设应用同步融合一体应用。"
          ]},
        {name:2021,text:[
            "推出集安防、消防、环保、防疫于一体化的综合智慧防控系统， 向城管、园区、校园、医院、工地、景区等行业场景进行智慧赋能。"
          ]},
      ],
      yearIndex:0,
    }
  },
  watch: {
    '$route':{
      handler:function (val){
        if(this.$route.query.type){
          this.$nextTick(()=>{
            this.$el.querySelector('#box-'+ this.$route.query.type).scrollIntoView()
          })
        }
      },
      immediate:true,
      deep: true
    }

  },
  methods:{
    changeYear(index){
      this.yearIndex=index;
      this.$refs.year.setActiveItem(index)
    }
  }
}
</script>

<style scoped lang="scss">
.companyProfile{
  .Introduction-box{
    width: 100%;
    height: 850px;
    background-image: url("../../assets/jianjie-bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 80px;
    >h1{
      text-align: center;
      font-size: 32px;
    }
    .Introduction-info{
      width: 1200px;
      height: 520px;
      margin:  0 auto;
      background-image: url("../../assets/jianjie-item-bg.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 100px 150px;
      p{
        margin: 5px 0;
      }
      >div{
        line-height: 1.5;
        font-size: 16px;
        font-weight: 400;
        >h1{
          text-align: center;
        }
      }
    }
    .Introduction-tools{
      width: 1100px;
      margin: 0 auto;
      margin-top: 20px;
      border-top: 2px solid #797979;
      color: #797979;
      font-size: 18px;
      display: flex;
      justify-content: center;
      >div{
        padding: 10px 20px;
        cursor: pointer;
        position: relative;
        top: -2px;
        border-top: 2px solid #797979;
      }
      >div.active{
        color: black;
        border-color: black;
      }
    }
  }
  .strategic-box{
    margin-top: 20px;
    width: 100%;
    height: 680px;
    background-image: url("../../assets/zhanlve.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 80px;
    >h1{
      text-align: center;
      font-size: 32px;
    }
    .strategic-type{
      width: 1000px;
      margin: 0 auto;
      padding-top: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >div{
        font-size: 28px;
        font-weight: bold;
      }
      .round{
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: black;
      }
    }
    .strategic-info{
      width: 1200px;
      margin: 0 auto;
      margin-top: 150px;
      display: flex;
      justify-content: space-between;
      h2{
        text-align: center;
      }
      .item-info{
        width: 550px;
        line-height: 1.5;
      }
    }
  }
  .application-box{
    margin: 0 auto;
    >h1{
      margin-top: 80px;
      text-align: center;
      font-size: 32px;
    }
    .text-info{
      text-align: center;
      font-size: 20px;
      font-weight: 500;
    }
    .application-content{
      margin-top: 50px;
    }
      .huiShi-bg{
        width: 100%;
        height: 100%;
        background-image: url("../../assets/huishi-bg.jpg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .azk-bg{
        width: 100%;
        height: 100%;
        background-image: url("../../assets/azk-bg.jpg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .by-bg{
      width: 100%;
      height: 100%;
      background-image: url("../../assets/by-bg.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
      .yjy-bg{
        width: 100%;
        height: 100%;
        background-image: url("../../assets/yjy-bg.jpg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .content{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 480px;
        >div{
         width: 550px;
        }
      }
  }
  .experience-box{
    height: 840px;
    margin-top: 50px;
    background-image: url("../../assets/licheng-bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    .experience-content{
      width: 100%;
      height: 620px;
      background-image: url("../../assets/licheng-img.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      .experience-info{
          margin-right: 20%;
          padding-top: 80px;
      }
    }
    .experience-tools{
      width: 100%;
      position: absolute;
      bottom: 50px;
      display: flex;
      justify-content: center;
      .tools-item{
        font-weight: bold;
        cursor: pointer;
        padding: 5px 20px;
      }
      .tools-item.active{
        color: #fff;
        border-bottom: 2px solid #fff;
      }
    }
  }
  .companyProfile-footer{
    width: 900px;
    margin:  0 auto;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
}
</style>
